<template>
    <div>
        <div class="header-top">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                        <div class="contact-info">
                            <i class="las la-map-marker"></i> Namanve Industrial Park, Uganda.
                            <i class="las la-envelope"></i> juliakigozi@gmail.com
                            <i class="las la-phone"></i> +256772609649
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12 text-end">
                        <div class="header_top_right">
                            <div class="social-area">
                                <a href="#"><i class="lab la-facebook-f"></i></a>
                                <a href="#"><i class="lab la-youtube"></i></a>
                                <a href="#"><i class="lab la-twitter"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Header Area -->

        <div class="header-area">
            <div class="sticky-area">
                <div class="navigation">
                    <div class="container-fluid">
                        <div class="header-inner-box">
                            <div class="logo">
                                <!-- <a class="navbar-brand" href="index-2.html">
                                    <img src="../assets/img/logo.png" alt="">
                                </a> -->
                                <router-link class="navbar-brand" to="/">
                                    UG PACKERS
                                </router-link>
                            </div>

                            <div class="main-menu flex-grow-1">
                                <nav class="navbar navbar-expand-lg">
                                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                        aria-expanded="false" aria-label="Toggle navigation">
                                        <span class="navbar-toggler-icon"></span>
                                        <span class="navbar-toggler-icon"></span>
                                        <span class="navbar-toggler-icon"></span>
                                    </button>

                                    <div class="collapse navbar-collapse"
                                        id="navbarSupportedContent">
                                        <ul class="navbar-nav">
                                            <li class="nav-item">
                                                <router-link to="/" class="nav-link">Home</router-link>
                                            </li>
                                            <li class="nav-item">
                                                <router-link to="/team" class="nav-link">Team</router-link>
                                            </li>
                                            <li class="nav-item">
                                                <router-link to="/contact" class="nav-link">Contact Us</router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>

                            <div class="header-btn ml-auto">
                                <a href="https://form256.packagingprobe.com/login" class="main-btn primary">Login</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NavBar"
}
</script>

<style scoped>
.header-area .header-inner-box {
    justify-content: unset;
}
</style>

<template>
    <div>
        <!-- Pre-Loader -->
        <!-- <div class="preloader"></div> -->

        <!-- Header Top Area -->
        <NavBar/>

        <div class="homepage-slides">
            <div class="single-slide-item">
                <div class="image-layer" style="background-image: url(assets/img/slider/slide-1.jpg);">
                    <div class="overlay"></div>
                </div>

                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-xl-8 col-lg-8 wow fadeInUp animated" data-wow-delay=".2s">
                            <div class="hero-area-content">
                                <div class="section-title">
                                    <h6>Welcome to Packaging Probe</h6>
                                    <h1>Manufacturer of Flexible Packaging</h1>
                                </div>
                                <p>The venture will establish a packaging facility and a shared user facility with metering and packaging equipment, enabling processors to access custom branded, high-quality packaging</p>
                                <router-link class="main-btn primary mt-30" to="#about-us">Learn More</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- About Section  -->

        <div class="about-area section-padding" id="about-us">
            <div class="container">
                <div class="row gx-5">
                    <div class="col-lg-6 col-xl-6 wow fadeInUp" data-wow-delay=".2s">
                        <div class="about-content-wrap">
                            <div class="section-title">
                                <p>Manufacturer of flexible Packaging and set up of a common User Packaging facility</p>
                                <h2 class="mt-0">Project Overview</h2>
                            </div>
                            <div class="about-content">
                                <div class="row">
                                    <div class="col-lg-12 col-xl-12">
                                        <div class="about-content-left">
                                            <p>
                                                To boost Uganda's industrialization, MSMEs need better competitiveness, 
                                                hindered by high packaging costs. Flexible packaging offers a solution with protection, convenience, and cost benefits. 
                                                This venture will set up a facility for quality, affordable flexible packaging and a shared facility for agro-processors. 
                                                Initial research will identify packaging needs. 
                                                The goal is to create custom packaging, promote its use, and generate 1000 jobs, 
                                                with 100% value addition and a 50% increase in agro-processor value extension.
                                            </p>

                                            <div class="row mt-30">
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-4">
                                                    <div class="featured-area">
                                                        <div class="featured-icon">
                                                            <i class="las la-business-time"></i>
                                                        </div>
                                                        <div class="featured-content">
                                                            <div class="featured-title">
                                                                <h5>Ontime <br>Scheduled</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-4">
                                                    <div class="featured-area">
                                                        <div class="featured-icon">
                                                            <i class="las la-stopwatch"></i>
                                                        </div>
                                                        <div class="featured-content">
                                                            <div class="featured-title">
                                                                <h5>24/7 <br>Services</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-4">
                                                    <div class="featured-area">
                                                        <div class="featured-icon">
                                                            <i class="las la-money-check-alt"></i>
                                                        </div>
                                                        <div class="featured-content">
                                                            <div class="featured-title">
                                                                <h5>Affordable <br>Cost</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 wow fadeInLeft" data-wow-delay=".4s">
                        <div class="about-img">
                            <img src="../assets/img/background.png" alt="">
                            <div class="about-counter">
                                <div class="counter-icon">
                                    <img src="../assets/img/icon/customer-service.png" alt="">
                                </div>
                                <div class="counter-number">
                                    <span class="counting" data-counterup-nums="154">4754</span>
                                </div>
                                <h6>Happy Customers</h6>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!--Service Area-->

        <div class="service-area sky-bg section-padding pb-50">
            <div class="container">
                <div class="section-title">
                    <h6>What we’re doing</h6>
                    <h2>Services We Offer</h2>
                </div>
                <div class="row">
                    <div class="col-xl-4 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="100ms"
                        style="visibility: visible; animation-delay: 100ms; animation-name: fadeInUp;">
                        <!--Services Single-->
                        <div class="services-two_single">
                            <div class="services-two_img-box">
                                <div class="services-two_img">
                                    <img src="../assets/img/background.png" alt="">
                                </div>
                                <div class="services-two_icon">
                                    <img src="../assets/img/icon/paper.png" alt="">
                                </div>
                            </div>
                            <div class="services-two_content">
                                <h3 class="services-two_title">Affordable and Felixible</h3>
                                <p class="services-two_text">This venture will provide affordable, branded flexible packaging and a shared packaging service for agro-processors.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="200ms"
                        style="visibility: visible; animation-delay: 200ms; animation-name: fadeInUp;">
                        <!--Services Two Single-->
                        <div class="services-two_single">
                            <div class="services-two_img-box">
                                <div class="services-two_img">
                                    <img src="../assets/img/background.png" alt="">
                                </div>
                                <div class="services-two_icon">
                                    <img src="../assets/img/icon/plastic.png" alt="">
                                </div>
                            </div>
                            <div class="services-two_content">
                                <h3 class="services-two_title">Packaging Quality</h3>
                                <p class="services-two_text">Initial market assessments will tailor services to needs, enhancing product quality and empowering processors with necessary skills and equipment.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="300ms"
                        style="visibility: visible; animation-delay: 300ms; animation-name: fadeInUp;">
                        <!--Services Two Single-->
                        <div class="services-two_single">
                            <div class="services-two_img-box">
                                <div class="services-two_img">
                                    <img src="../assets/img/background.png" alt="">
                                </div>
                                <div class="services-two_icon">
                                    <img src="../assets/img/icon/glass.png" alt="">
                                </div>
                            </div>
                            <div class="services-two_content">
                                <h3 class="services-two_title"><a href="glass_recycle.html">Innovation and Creativity</a></h3>
                                <p class="services-two_text">High tariffs on imports hinder agro-processors and poor local packaging hinder agro-processors, necessitating local innovation.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Process Section  -->

        <div class="process-area dark-bg section-padding">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xl-6 col-lg-6">
                        <div class="section-title">
                            <h6>Project Progress  </h6>
                            <h2 class="text-white">Project Steps for Our <br> Packaging Material</h2>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6">
                        <div class="process-right-content">
                            <p class="text-white">Packaging material involves data collection to design and produce containers and wrappers, followed by distribution to ensure timely delivery and meet industry standards. The process covers protection, preservation, and presentation of products.. </p>
                            <!-- <p class="text-white">This Waste Management video shows the step-by-step process of how
                                materials are processed once they reach a facility.</p> -->
                            <div class="process-btn">
                                <a href="contact.html" class="main-btn">Get Started</a>
                                <a href="#" class="main-btn white">View Our Plans</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="process-item-wrap">
                    <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay=".2s">
                            <div class="process-single">
                                <div class="process-icon">
                                    <img src="../assets/img/process/1.png" alt="">
                                </div>
                                <div class="process-title">
                                    <h5>Data Collection</h5>
                                </div>
                                <div class="process-desc">
                                    <p>Value chain analysis from agro-processors,Raw material suppliers, exporters, regulators, Manufacturers, Consumers & retailers.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay=".4s">
                            <div class="process-single two">
                                <div class="process-icon">
                                    <img src="../assets/img/process/2.png" alt="">
                                </div>
                                <div class="process-title">
                                    <h5>Manufacturing</h5>
                                </div>
                                <div class="process-desc">
                                    <p>involves producing containers and wrappers from various materials, focusing on design, production, and sustainability.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay=".6s">
                            <div class="process-single three">
                                <div class="process-icon">
                                    <img src="../assets/img/process/3.png" alt="">
                                </div>
                                <div class="process-title">
                                    <h5>Production</h5>
                                </div>
                                <div class="process-desc">
                                    <p>Production of high quality, Felixible and affordable Packaging material to accommodate all kinds of Products to cater to the lowest consumer
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay=".8s">
                            <div class="process-single four">
                                <div class="process-icon">
                                    <img src="../assets/img/process/4.png" alt="">
                                </div>
                                <div class="process-title">
                                    <h5>Distribution</h5>
                                </div>Involves supplying and delivering packaging material to businesses, ensuring product protection, preservation, presentation, and timely availability to meet standards and needs.
                                <div class="process-desc">
                                    <p>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Choose Us Area  -->

        <div class="choose_us section-padding">
            <div class="container">
                <div class="row">
                    <div class="col-xl-6 col-lg-6 wow fadeInLeft" data-wow-delay=".2s">
                        <div class="choose_us_left">
                            <div class="choose_us_img">
                                <img src="../assets/img/background.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 wow fadeInUp" data-wow-delay=".4s">
                        <div class="choose_us_right">
                            <div class="section-title text-left">
                                <h6>Our Core Feature</h6>
                                <h2>Our Mission</h2>
                            </div>
                            <ol class="mission">
                                <li>To Conduct value chain mapping and analysis of the flexible packaging value chains.</li>
                                <li>To set up and commercialize a packaging manufacturing facility for affordable and quality flexible packages.</li>
                                <li>Set up Common User Facilities for metering and unit packaging.</li>
                                <li>To develop prototypes and manufacturing processes for flexible packages.</li>
                            </ol>
                            <!-- <p class="choose_us_right-text">There might be a lot of talk about reusing and recycling
                                these
                                days but taking discarded materials and turning them into something new is by no means a
                                modern concept. People have been discarding and reusing materials for a very long time
                                ago.
                            </p>
                            <ul class="list-unstyled choose_us_points">
                                <li>
                                    <div class="icon">
                                        <i class="las la-check"></i>
                                    </div>
                                    <div class="text">
                                        <p>Convenient Pickup</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="icon">
                                        <i class="las la-check"></i>
                                    </div>
                                    <div class="text">
                                        <p>Reducing Waste</p>
                                    </div>
                                </li>
                            </ul> -->

                            <!-- <div class="progress-bar-area">
                                <div class="single-bar-item">
                                    <h4>Recycling Service</h4>
                                    <div id="bar1" class="barfiller">
                                        <span class="tip">90%</span>
                                        <span class="fill" data-percentage="90"></span>
                                    </div>
                                </div>

                                <div class="single-bar-item">
                                    <h4>Waste Management</h4>
                                    <div id="bar2" class="barfiller">
                                        <span class="tip">70%</span>
                                        <span class="fill" data-percentage="70"></span>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <FooterComponet />

        <!-- Scroll Top Area -->
        <a href="#top" class="go-top"><i class="las la-angle-up"></i></a>
    </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterComponet from "@/components/Footer.vue"

export default {
    name: "HomePage",
    components: {
        NavBar,
        FooterComponet
    }
}
</script>
<style scoped>

ol.mission li {
    display: block;
    padding-top: 15px;
}

</style>

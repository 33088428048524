<template>
    <div>
        <!-- Footer Area -->

        <footer class="footer-area">
            <div class="container">
                <div class="footer-up">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <div class="logo">
                                <!-- <img src="../assets/img/logo-white.png" alt="ecofix-logo"> -->
                                <h4 style="color: azure;">UG PACKERS</h4>
                            </div>
                            <div class="contact-info">
                                <p><b>Location:</b> Namanve, Industial Park Uganda</p>
                                <p><b>Phone:</b> +256772609649</p>
                                <p><b>E-mail:</b> juliakigozi@gmail.com</p>
                            </div>

                        </div>
                        <div class="col-lg-5 col-md-6 com-sm-12">

                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-12">
                                    <h6>Company</h6>
                                    <ul>
                                        <li>
                                            <router-link to="/team">Meet Our Team</router-link>
                                            <a href="#">About US</a>
                                            <router-link to="/contact">Contact</router-link>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12">
                                    
                                </div>

                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6">
                            <div class="subscribe-form">
                                <h6>Newsletter</h6>
                                <form action="#">
                                    <input type="email" placeholder="Your email">
                                    <button type="submit"><i class="las la-envelope"></i></button>
                                </form>
                                <p>Stay tuned for our latest news</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

        <!-- Footer Bottom Area  -->

        <div class="footer-bottom">
            <div class="container">
                <div class="row justify-content-center align-items-center">
                    <!-- <div class="col-lg-3 col-12">
                        <div class="copyright-area">
                            <p class="copyright-line">© 2024 Ecofix. All rights reserved.</p>
                        </div>
                    </div> -->
                    <div class="col-lg-6 col-12">
                        <p class="privacy"><a href="#">Terms &amp; Conditions</a> <a href="#">Privacy Policy</a> <a
                                href="#">Packaging Tips</a> </p>
                    </div>
                    <div class="col-lg-3 col-12 text-end">
                        <div class="social-area">
                            <a href="#"><i class="lab la-facebook-f"></i></a>
                            <a href="#"><i class="lab la-youtube"></i></a>
                            <a href="#"><i class="lab la-twitter"></i></a>
                            <a href="#"><i class="lab la-instagram"></i></a>
                            <a href="#"><i class="lab la-linkedin"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FooterComponent"
}
</script>
